import React, { useEffect, useState, useMemo } from "react";
import { colorList } from "../../LineColors";
import { formatValue } from "common/utilities/FormatValue";
import { interpolatePath } from "d3-interpolate-path";

import { mainStyle } from "common/MainStyle";
import { previewColors } from "../previewColors";
import { TooltipStyles } from "common/graphics/TooltipStyles";
import { TimeChartProps } from "./index";
import * as d3 from "d3";
import Portal from "common/Portal";
import D3ChartBase, { AxisType } from "common/graphics/v2/D3ChartBase";
import AnimationSlider from "common/AnimationSlider";
import { formatNumber, NumberFormat, Unpacked } from "common/Canvas";
import {
    dateTimeFormatOptionsForLevels,
    strftime,
} from "common/utilities/TimeFormatUtils";
import { useUpdateEffect } from "common/CustomHooks";
import { getTextSize } from "common/utilities/MeasureText";
import { getLongestYAxisValue } from "../utils";
import { LineType, TickLabelOrientation } from "common/Finding";
// import { TooltipContent } from "common/ConfigurableTooltip";

interface Datum {
    [key: string]: string | number;
}

type PointGroup = {
    name: string | number;
    y: number;
    offset: number;
}[];

type YVariables = {
    name: string;
    value: (number | string | undefined)[][];
    variableIndex?: number;
    originalName?: string;
}[];

function color(
    yVariables: YVariables,
    groupByAll: boolean,
    preview: boolean,
    nameColorMapping: TimeChartProps["config"]["nameColorMapping"],
    groupNameColorMapping: TimeChartProps["config"]["groupNameColorMapping"],
    yVariable: { variableIndex?: number } | undefined | null,
    groupIndex: number,
    yVariableIndex: number
): string {
    let colorListIndex = yVariableIndex + groupIndex * yVariables.length;
    if (!groupByAll) {
        return preview ||
            (yVariable?.variableIndex == null &&
                nameColorMapping?.[yVariableIndex] == null)
            ? previewColors[yVariableIndex % previewColors.length]
            : nameColorMapping?.[yVariableIndex] ??
                  colorList[yVariableIndex % colorList.length];
    } else {
        return preview ||
            (yVariable?.variableIndex == null &&
                groupNameColorMapping?.[groupIndex]?.[yVariableIndex] == null)
            ? previewColors[colorListIndex % previewColors.length]
            : groupNameColorMapping?.[groupIndex]?.[yVariableIndex] ??
                  colorList[colorListIndex % colorList.length];
    }
}

function stringToValidSelector(strVal: string): string {
    // generate hashcode
    let validStr = strVal
        .split("")
        .reduce(
            (prevHash, currVal) =>
                ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
            0
        )
        .toString();
    return validStr;
}

function buildPointGroupData(data: {
    chartData: Datum[];
    yVariable: YVariables[number];
    timeKey: string;
    stacked: boolean;
}) {
    const { chartData, yVariable, timeKey, stacked } = data;
    return chartData
        .map((item) => {
            type ItemIndexType = keyof typeof item;
            return {
                name: item[timeKey as ItemIndexType],
                y: item[yVariable.name as ItemIndexType] as number,
                offset: stacked
                    ? (item[
                          `${yVariable.name}_offset` as ItemIndexType
                      ] as number)
                    : 0,
            };
        })
        .filter((item: Unpacked<PointGroup>) => item.y != null);
}

function formatXDateLabel(
    format: string | null | undefined,
    timestamp: number
): string {
    return strftime(format ?? "%Y %b %-d", new Date(timestamp * 1000), true);
}

export function formatLabel(
    format: NumberFormat | undefined,
    value: string | number | null
): string {
    let label = "";
    if (format && format?.numberType) {
        label = formatNumber(value as number | null, format);
    } else {
        label = formatValue(value, false).join("");
    }
    return label;
}

export function TimeChartD3(props: TimeChartProps) {
    let { config, data, isTimeSeries, onDotClick } = props;
    let [showTooltipInfo, setShowTooltipInfo] = useState<{
        x: number;
        y: number;
        key: string;
        keyValue: number;
        points: {
            yValue: number;
            dataIndex: number;
            pointIndex: number;
            yVariableIndex: number;
        }[];
    } | null>(null);
    let [nextChartDatasetIndex, setNextChartDatasetIndex] = useState<number>(0);
    let [longestYAxisValue, setLongestYAxisValue] = useState<string>("0");
    let [colorPickerIsEnabled, setColorPickerIsEnabled] = useState<boolean>(
        false
    );
    let {
        ticksColor,
        axesLinesColor,
        tooltipColor,
        tooltipFontSize,
        ticksSize,
        ticksAndLabels,
    } = config;
    let parentRef = React.useRef<HTMLDivElement>(null);
    let svgRef = React.useRef<SVGSVGElement>(null);

    let xScale: React.MutableRefObject<
        ((value: string | number) => number | undefined) | null
    > = React.useRef(null);
    let yScale: React.MutableRefObject<d3.ScaleLinear<
        number,
        number
    > | null> = React.useRef(null);
    let modifiedChartRefData: React.MutableRefObject<
        Datum[] | null
    > = React.useRef(null);
    let xOffset = React.useRef<number>(0);

    let circlesRef = React.useRef<
        d3.Selection<
            SVGCircleElement | d3.BaseType,
            Datum,
            SVGGElement,
            unknown
        >
    >();

    let tooltipCirclesRef = React.useRef<
        d3.Selection<
            SVGCircleElement | d3.BaseType,
            Datum,
            SVGGElement,
            unknown
        >
    >();

    let firstRawDate = React.useRef<string | number>();
    let chartOptionsRef = React.useRef<{
        height: number;
        svg: d3.Selection<SVGGElement, unknown, null, undefined>;
    } | null>(null);
    let verticalLineRef = React.useRef<
        d3.Selection<SVGGElement, unknown, null, undefined> | undefined
    >();

    let xAxisFontSize: number =
        ticksAndLabels?.x?.size ??
        ticksSize ??
        parseInt(mainStyle.getPropertyValue("--graphs-axes-size"));

    let yAxisFontSize: number =
        ticksAndLabels?.y?.size ??
        ticksSize ??
        parseInt(mainStyle.getPropertyValue("--graphs-axes-size"));

    // Do not remove this code. It's for a future task.
    // const tooltipData = useMemo(() => {
    //     let tooltipData: {
    //         [key: string]: string | number;
    //     }[] = [];
    //     if (props.index < 0) {
    //         for (let i = 0; i < data[0].value[0].length; ++i) {
    //             let item: {
    //                 [key: string]: string | number;
    //             } = {};
    //             props.groupNames.forEach((_, groupIndex) => {
    //                 item[String(data[1].variableIndex)] =
    //                     data[1].value[groupIndex][i];
    //             });
    //             tooltipData.push(item);
    //         }
    //     } else {
    //         for (let i = 0; i < data[0].value[0].length; ++i) {
    //             let item: {
    //                 [key: string]: string | number;
    //             } = {};
    //             props.data.slice(1).forEach((dataItem) => {
    //                 item[String(dataItem.variableIndex)] =
    //                     dataItem.value[props.index][i];
    //             });
    //             tooltipData.push(item);
    //         }
    //     }
    //     return tooltipData;
    // }, [props.index, props.groupNames, props.content.data]);

    function updateChart(idx: number) {
        const { uniqueValues } = props.content.time!;
        let prevChartFilteredData: Datum[] = [];
        let nextChartFilteredData: Datum[] = [];
        let timeKey: string = data[0]?.name;
        let yVariables: YVariables = [];

        for (let i = 1; i < data.length; ++i) {
            if (data[i].value.length !== 0 && data[i].value[0].length !== 0) {
                yVariables.push({
                    ...data[i],
                });
            }
        }

        prevChartFilteredData =
            modifiedChartRefData.current?.filter(
                (d) => d.time === uniqueValues[idx !== 0 ? idx - 1 : idx]
            ) ?? [];

        nextChartFilteredData =
            modifiedChartRefData.current?.filter(
                (d) =>
                    d.time ===
                    uniqueValues[idx !== uniqueValues.length ? idx : idx - 1]
            ) ?? [];

        const line = d3
            .line<Datum>()
            .curve(d3.curveLinear)
            .x((d) => xScale.current!(d.name)!)
            .y((d) => yScale.current!(d.y as number))
            .curve(d3.curveMonotoneX);

        // Build chart lines
        for (let yVariable of yVariables.values()) {
            const prevPointGroup: PointGroup = buildPointGroupData({
                chartData: prevChartFilteredData,
                yVariable,
                timeKey,
                stacked: config.stacked && config.isArea,
            });
            const nextPointGroup: PointGroup = buildPointGroupData({
                chartData: nextChartFilteredData,
                yVariable,
                timeKey,
                stacked: config.stacked && config.isArea,
            });

            const lineClass = `.line-${stringToValidSelector(yVariable.name)}`;

            d3.select<SVGPathElement, PointGroup>(lineClass)
                .transition()
                .duration(1000)
                .ease(d3.easeLinear)
                .attrTween("d", () =>
                    interpolatePath(
                        line(prevPointGroup)!,
                        line(nextPointGroup)!
                    )
                );
        }

        // Build chart circles
        for (let yVariable of yVariables.values()) {
            d3.select(
                `#circlesContainer-${stringToValidSelector(yVariable.name)}`
            )
                .selectAll<SVGCircleElement, Datum>("circle")
                .data(nextChartFilteredData)
                .join("circle")
                .attr("cx", (d) => xScale.current!(d[timeKey])!)
                .transition()
                .duration(1000)
                .ease(d3.easeLinear)
                .attr("cy", (d) => {
                    if (config.stacked && config.isArea) {
                        return yScale.current!(
                            (d[yVariable.name] as number) +
                                (d[`${yVariable.name}_offset`] as number)
                        );
                    }
                    return yScale.current!(d[yVariable.name] as number);
                })
                .attr("r", config.dotRadius ?? 4)
                .attr("fill", "grey")
                .attr("stroke", "grey");
        }
    }

    useUpdateEffect(() => {
        setTimeout(() => {
            setColorPickerIsEnabled(props.selected);
        }, 100);
    }, [props.selected]);

    let dataIndices: number[] = useMemo(
        () =>
            props.index < 0
                ? data[0].value.map((_, index) => index)
                : [props.index],
        [props.index, data]
    );

    const xVariableName = data[0]?.name;
    const xVariable = data[0];

    let yVariables: YVariables = useMemo(() => {
        let yVariables: YVariables = [];
        for (let i = 1; i < data.length; ++i) {
            if (data[i].value.length !== 0) {
                yVariables.push({
                    ...data[i],
                });
            }
        }
        return yVariables;
    }, [data]);

    let [modifiedData, dataYMin, dataYMax, dataXMin, dataXMax] = useMemo(() => {
        let modifiedData: Datum[][] = [];
        let dataYMin = Infinity;
        let dataYMax = -Infinity;
        let dataXMin = Infinity;
        let dataXMax = -Infinity;

        for (let dataIndex of dataIndices) {
            const rangedData = data[0]?.value[dataIndex] ?? [];

            let curve: Datum[] = [];
            for (let i = 0; i < rangedData.length; ++i) {
                let item: { [key: string]: string | number } = {};
                item[xVariableName] = data[0].value?.[dataIndex][i] ?? null;
                dataXMin = Math.min(item[xVariableName] as number, dataXMin);
                dataXMax = Math.max(item[xVariableName] as number, dataXMax);
                item.time = props.content.time?.value?.[i] ?? "";
                let sum: number = 0;
                for (let j = 0; j < yVariables.length; ++j) {
                    if (config.stacked && config.isArea) {
                        item[yVariables[j].name] =
                            (yVariables[j].value?.[dataIndex]?.[i] as number) ??
                            null;
                        item[`${yVariables[j].name}_offset`] = sum ?? null;
                        sum =
                            sum +
                            (yVariables[j].value?.[dataIndex]?.[i] as number);
                        if (!isNaN(sum)) {
                            dataYMin = Math.min(sum, dataYMin);
                            dataYMax = Math.max(sum, dataYMax);
                        }
                    } else {
                        item[yVariables[j].name] =
                            (yVariables[j].value?.[dataIndex]?.[i] as number) ??
                            null;

                        if (item[yVariables[j].name] != null) {
                            dataYMin = Math.min(
                                item[yVariables[j].name] as number,
                                dataYMin
                            );
                            dataYMax = Math.max(
                                item[yVariables[j].name] as number,
                                dataYMax
                            );
                        }
                    }
                }
                item.pointIndex = i;
                curve.push(item);
            }
            // Do not use push here. It should be a sparse array if there is
            // only one index.
            modifiedData[dataIndex] = curve;
        }
        return [modifiedData, dataYMin, dataYMax, dataXMin, dataXMax];
    }, [
        data,
        props.content.time,
        config.stacked,
        config.isArea,
        dataIndices,
        xVariableName,
        yVariables,
    ]);

    let minXRange: number = props.config.minXRange ?? dataXMin;
    let maxXRange: number = props.config.maxXRange ?? dataXMax;
    let minYRange: number = props.config.minYRange ?? dataYMin;
    let maxYRange: number = props.config.maxYRange ?? dataYMax;

    const yAxisWidth = 50;
    const xAxisHeight = 50;

    React.useEffect(() => {
        let xLabelOrientation =
            props.config?.ticksAndLabels?.x?.labelOrientation ??
            TickLabelOrientation.Horizonal;
        let yLabelOrientation =
            props.config?.ticksAndLabels?.y?.labelOrientation ??
            TickLabelOrientation.Horizonal;

        firstRawDate.current = data[0]?.value?.[dataIndices[0] ?? 0][0] ?? 0;

        let type: "number" | "category" = "category";
        if (
            data.length > 0 &&
            data[0].value?.[dataIndices[0] ?? 0].length > 0 &&
            isTimeSeries != null
        ) {
            if (typeof firstRawDate.current! === "number") type = "number";
        }

        const yAxisExtraSpace =
            getTextSize(longestYAxisValue, "Roboto", yAxisFontSize, "normal")
                .width + 15;

        const xAxisExtraSpace =
            getTextSize("0", "Roboto", xAxisFontSize, "normal").height / 2 - 22;

        const bgDarkColor =
            axesLinesColor ??
            mainStyle.getPropertyValue("--graphs-stroke-color");

        // Padding is necessary to prevent tick text from being cut off
        let paddingRight = 0;
        let fullHeight = parentRef.current?.clientHeight ?? 0;
        let fullWidth = parentRef.current?.clientWidth ?? 0;
        // set the dimensions and margins of the graph
        let height = fullHeight - xAxisHeight;
        // We subtract yAxisExtraSpace to prevent the chart from shifting to
        // the right and cutting off axis label
        // https://eisengardai.atlassian.net/browse/EIS-259?focusedCommentId=12079
        let width = fullWidth - yAxisWidth - paddingRight - yAxisExtraSpace;

        // append the svg object to the body of the page
        d3.select(svgRef.current!).selectAll("*").remove();
        let svg = d3
            .select(svgRef.current!)
            .attr("width", fullWidth)
            .attr("height", fullHeight)
            .attr("viewBox", null)
            .append("g")
            .attr(
                "transform",
                `translate(${yAxisExtraSpace}, ${-xAxisExtraSpace})`
            );

        let base = new D3ChartBase(svg, width, height);

        // Add X axis
        let xAxis: d3.Axis<string> | d3.Axis<number>;
        let xAxisG: d3.Selection<SVGGElement, unknown, null, undefined>;
        let xScaleBand: d3.ScaleBand<string> | undefined;
        let xScaleLinear: d3.ScaleLinear<number, number> | undefined;
        xScale.current = (value: string | number) =>
            xScaleBand != null
                ? xScaleBand(value as string)
                : xScaleLinear!(value as number);
        xOffset.current = 0;
        const axisAbsolutePadding = config.dotRadius ?? 4;
        if (type === "category") {
            let bandAxis = base.drawBandAxis(
                AxisType.XAxis,
                {
                    color: bgDarkColor,
                },
                {
                    color:
                        ticksColor ??
                        mainStyle.getPropertyValue("--graphs-axes-text-color"),
                    fontSize: xAxisFontSize,
                    tickFormat: (value) => {
                        let label = "";
                        if (isTimeSeries) {
                            label = formatXDateLabel(
                                props.config?.formatAxises?.xAxisDate?.value ??
                                    dateTimeFormatOptionsForLevels[
                                        props.config?.ticksAndLabels?.x
                                            ?.intervalUnit ?? "day"
                                    ][0].value,
                                Number(value)
                            );
                        } else {
                            label = formatLabel(
                                props.config?.formatAxises?.xAxis,
                                value
                            );
                        }

                        return label;
                    },
                },
                modifiedData
                    .map((curve) => curve.map((d) => String(d[xVariableName])))
                    .flat()
            );
            xAxis = bandAxis.axis;
            xAxisG = bandAxis.g;
            xScaleBand = xAxis.scale<d3.ScaleBand<string>>();
            xOffset.current = xScaleBand.bandwidth() / 2;
        } else {
            let xInterval =
                props.config?.ticksAndLabels?.x?.interval == null
                    ? null
                    : isTimeSeries &&
                      props.config?.ticksAndLabels?.x?.interval != null
                    ? {
                          amount: props.config?.ticksAndLabels?.x?.interval,
                          unit:
                              props.config?.ticksAndLabels?.x?.intervalUnit ??
                              "day",
                      }
                    : props.config?.ticksAndLabels?.x?.interval;
            let { ticks: xTicks } = D3ChartBase.calculateLinearTicks(
                minXRange,
                maxXRange,
                xInterval,
                props.config?.formatAxises?.xAxis?.decimalPoints
            );
            // The chart would break if there are too many ticks
            if (xTicks.length > 100 * 2) {
                let newXTicks: number[] = [];
                let interval = Math.floor(xTicks.length / 100);
                for (let i = 0; i < xTicks.length; i += interval) {
                    newXTicks.push(xTicks[i]);
                }
                xTicks = newXTicks;
            }
            let linearAxis = base.drawLinearAxis(
                AxisType.XAxis,
                {
                    color: bgDarkColor,
                },
                {
                    color:
                        ticksColor ??
                        mainStyle.getPropertyValue("--graphs-axes-text-color"),
                    fontSize: xAxisFontSize,
                    tickSize: 0,
                    tickValues: xTicks,
                    tickFormat: (value) => {
                        let label = "";
                        if (isTimeSeries) {
                            label = formatXDateLabel(
                                props.config?.formatAxises?.xAxisDate?.value ??
                                    dateTimeFormatOptionsForLevels[
                                        props.config?.ticksAndLabels?.x
                                            ?.intervalUnit ?? "day"
                                    ][0].value,
                                value
                            );
                        } else {
                            label = formatLabel(
                                props.config?.formatAxises?.xAxis,
                                value
                            );
                        }

                        return label;
                    },
                },
                [minXRange, maxXRange],
                undefined,
                {
                    start: axisAbsolutePadding,
                    end: axisAbsolutePadding,
                }
            );
            xAxis = linearAxis.axis;
            xAxisG = linearAxis.g;
            xScaleLinear = xAxis.scale<d3.ScaleLinear<number, number>>();
        }

        if (xLabelOrientation === TickLabelOrientation.Slanted) {
            xAxisG
                .selectAll("text")
                .attr("transform", "translate(0,7), rotate(-20)");
        } else if (xLabelOrientation === TickLabelOrientation.Vertical) {
            xAxisG
                .selectAll("text")
                .attr("text-anchor", "end")
                .attr("alignment-baseline", "center")
                .attr("transform", `translate(-14, 9), rotate(-90)`);
        }

        // Add Y axis
        const yAxisFormat = props.config?.formatAxises?.yAxis;
        let { ticks: yTicks } = D3ChartBase.calculateLinearTicks(
            minYRange,
            maxYRange,
            props.config?.ticksAndLabels?.y?.interval,
            yAxisFormat?.decimalPoints
        );

        let yTickFormat = (value: number) => formatLabel(yAxisFormat, value);

        let { axis: yAxis, g: yAxisG } = base.drawLinearAxis(
            AxisType.YAxis,
            {
                color: bgDarkColor,
            },
            {
                color:
                    ticksColor ??
                    mainStyle.getPropertyValue("--graphs-axes-text-color"),
                fontSize: yAxisFontSize,
                tickSize: 0,
                tickValues: yTicks,
                tickFormat: yTickFormat,
            },
            [minYRange, maxYRange],
            undefined,
            {
                start: axisAbsolutePadding,
                end: axisAbsolutePadding,
            }
        );
        yScale.current = yAxis.scale<d3.ScaleLinear<number, number>>();

        if (yLabelOrientation === TickLabelOrientation.Slanted) {
            yAxisG.selectAll("text").attr("transform", "rotate(-20)");
        } else if (yLabelOrientation === TickLabelOrientation.Vertical) {
            yAxisG
                .selectAll("text")
                .attr("text-anchor", "center")
                .attr("transform", `translate(-15, -14), rotate(-90)`);
        }

        const longestYValue = getLongestYAxisValue(
            yTicks,
            props.config?.ticksAndLabels?.y?.interval != null,
            "lineplot",
            yAxisFormat
        );

        if (longestYAxisValue !== longestYValue) {
            setLongestYAxisValue(longestYValue);
        }

        base.drawGrid([], yTicks, bgDarkColor, () => 0, yScale.current, true);
        for (let dataIndex of dataIndices) {
            let g = svg.append("g");
            if (props.content.time && props.content.time.originalName) {
                modifiedChartRefData.current = modifiedData[dataIndex];

                modifiedData[dataIndex] = modifiedData[dataIndex].filter(
                    (d) =>
                        d.time ===
                        props.content.time!.uniqueValues[nextChartDatasetIndex]
                );
            }

            for (let [index, yVariable] of yVariables.entries()) {
                let hasData = (yVariable?.value?.[dataIndex]?.length ?? 0) > 0;
                if (!hasData) continue;
                let pointGroup: PointGroup = buildPointGroupData({
                    chartData: modifiedData[dataIndex],
                    yVariable: yVariable,
                    timeKey: xVariableName,
                    stacked: config.stacked,
                });

                let svgDefs = svg.append("defs");

                // Mask to prevent drawing out of y axis
                let clipMaskId = `TimeChartD3-clip-mask-${dataIndex}-${index}-${props.dashboardId}`;
                svgDefs
                    .append("mask")
                    .attr("id", clipMaskId)
                    // This is necessary to fix the issue where straight paths
                    // disappeared: https://eisengardai.atlassian.net/browse/EIS-354
                    .attr("maskUnits", "userSpaceOnUse")
                    .append("rect")
                    .attr("x", "0")
                    .attr("y", "0")
                    .attr(
                        "width",
                        `calc(100% - ${
                            yAxisWidth + paddingRight + yAxisExtraSpace
                        }px)`
                    )
                    .attr("height", `calc(100% - ${xAxisHeight}px)`)
                    .attr("fill", "#fff");

                if (pointGroup.length > 1) {
                    let fillColor = color(
                        yVariables,
                        props.index < 0,
                        props.preview ?? false,
                        config.nameColorMapping,
                        config.groupNameColorMapping,
                        yVariable,
                        dataIndex,
                        index
                    );

                    let path = g
                        .append("path")
                        .datum(pointGroup)
                        .attr(
                            "class",
                            `line-${stringToValidSelector(yVariable.name)}`
                        )
                        .attr("stroke", fillColor)
                        .attr(
                            "stroke-dasharray",
                            config.lineType === LineType.Dashed ? 4 : null
                        )
                        .attr("stroke-width", 1.5);
                    if (!config.isArea) {
                        const line0 = d3
                            .line<typeof pointGroup[0]>()
                            .x(
                                (d) =>
                                    xScale.current!(d.name)! + xOffset.current
                            )
                            .y((d) => yScale.current!(d.y))
                            .curve(d3.curveMonotoneX);
                        path.attr("d", line0)
                            .attr("mask", `url(#${clipMaskId})`)
                            .attr("fill", "none");
                    } else if (config.gradientArea) {
                        let svgDefs = svg.append("defs");

                        let filterId = `TimeChartD3-filter-${dataIndex}-${index}-${props.dashboardId}`;
                        svgDefs
                            .append("filter")
                            .attr("id", filterId)
                            .attr("x", "0")
                            .attr("y", "0")
                            .append("feGaussianBlur")
                            .attr("in", "SourceGraphic")
                            .attr("stdDeviation", 20);

                        let maskId = `TimeChartD3-mask-${dataIndex}-${index}-${props.dashboardId}`;
                        svgDefs
                            .append("mask")
                            .attr("id", maskId)
                            // This is necessary to fix the issue where straight paths
                            // disappeared: https://eisengardai.atlassian.net/browse/EIS-354
                            .attr("maskUnits", "userSpaceOnUse")
                            .datum(pointGroup)
                            .append("path")
                            .attr("fill", "#ffffff")
                            .attr(
                                "d",
                                d3
                                    .area<typeof pointGroup[0]>()
                                    .x(
                                        (d) =>
                                            xScale.current!(d.name)! +
                                            xOffset.current
                                    )
                                    .y0((d) => {
                                        if (d.offset === 0) return height;
                                        return yScale.current!(d.offset);
                                    })
                                    .y1((d) => yScale.current!(d.y + d.offset))
                            );

                        // Line
                        path.attr(
                            "d",
                            d3
                                .line<typeof pointGroup[0]>()
                                .x(
                                    (d) =>
                                        xScale.current!(d.name)! +
                                        xOffset.current
                                )
                                .y((d) => yScale.current!(d.y + d.offset))
                        )
                            .attr("fill", "none")
                            .attr("mask", `url(#${clipMaskId})`);

                        // Blur
                        g.append("g")
                            // We can't apply multiple masks to path, so we
                            // need to apply this one to a parent g
                            .style("mask", `url(#${clipMaskId})`)
                            .append("path")
                            .datum(pointGroup)
                            .attr(
                                "class",
                                `line-${stringToValidSelector(yVariable.name)}`
                            )
                            .attr("stroke", fillColor)
                            .attr("stroke-width", 15)
                            .attr("fill", "none")
                            .attr("filter", `url(#${filterId})`)
                            .attr("mask", `url(#${maskId})`)
                            .attr(
                                "d",
                                d3
                                    .line<typeof pointGroup[0]>()
                                    .x(
                                        (d) =>
                                            xScale.current!(d.name)! +
                                            xOffset.current
                                    )
                                    .y((d) => yScale.current!(d.y + d.offset))
                            );
                    } else {
                        path.attr("fill", fillColor)
                            .attr("fill-opacity", 0.5)
                            .attr(
                                "d",
                                d3
                                    .area<typeof pointGroup[0]>()
                                    .x(
                                        (d) =>
                                            xScale.current!(d.name)! +
                                            xOffset.current
                                    )
                                    .y0((d) => {
                                        if (d.offset === 0) return height;
                                        return yScale.current!(d.offset);
                                    })
                                    .y1((d) => yScale.current!(d.y + d.offset))
                            )
                            .attr("mask", `url(#${clipMaskId})`);
                    }
                }
            }
            for (let [index, yVariable] of yVariables.entries()) {
                const dotColor = (_d: Datum, i: number) => {
                    return (
                        config.dotColors?.[dataIndex.toString()]?.[
                            index.toString()
                        ]?.[i.toString()] ?? "grey"
                    );
                };
                circlesRef.current = svg
                    .append("g")
                    // .selectAll("g")
                    // .data(yVariables)
                    // .join("g")
                    .attr(
                        "id",
                        `circlesContainer-${stringToValidSelector(
                            yVariable.name
                        )}`
                    )
                    .selectAll("circle")
                    .data(modifiedData[dataIndex])
                    .join("circle")
                    .attr(
                        "cx",
                        (d) =>
                            xScale.current!(d[xVariableName])! + xOffset.current
                    )
                    .attr("cy", (d) => {
                        if (config.stacked && config.isArea) {
                            return yScale.current!(
                                (d[yVariable.name] as number) +
                                    (d[`${yVariable.name}_offset`] as number)
                            );
                        }
                        return yScale.current!(d[yVariable.name] as number);
                    })
                    .attr("r", config.dotRadius ?? 4)
                    .attr("fill", dotColor)
                    .attr("stroke", dotColor)
                    .attr("hidden", (d) =>
                        d[yVariable.name] == null ||
                        d[xVariable.name] < minXRange ||
                        d[xVariable.name] > maxXRange ||
                        d[yVariable.name] < minYRange ||
                        d[yVariable.name] > maxYRange
                            ? true
                            : null
                    );

                tooltipCirclesRef.current = svg
                    .append("g")
                    .attr(
                        "id",
                        `tooltipCirclesContainer-${stringToValidSelector(
                            yVariable.name
                        )}`
                    )
                    .selectAll("circle")
                    .data(modifiedData[dataIndex])
                    .join("circle")
                    .attr(
                        "cx",
                        (d) =>
                            xScale.current!(d[xVariableName])! + xOffset.current
                    )
                    .attr("cy", (d) => {
                        if (config.stacked && config.isArea) {
                            return yScale.current!(
                                (d[yVariable.name] as number) +
                                    (d[`${yVariable.name}_offset`] as number)
                            );
                        }
                        return yScale.current!(d[yVariable.name] as number);
                    })
                    .attr("r", Math.max(config.dotRadius ?? 4, 4))
                    .attr("fill", "transparent")
                    .attr("stroke", "transparent")
                    .attr("hidden", (d) =>
                        d[yVariable.name] == null ||
                        d[xVariable.name] < minXRange ||
                        d[xVariable.name] > maxXRange ||
                        d[yVariable.name] < minYRange ||
                        d[yVariable.name] > maxYRange
                            ? true
                            : null
                    )
                    .on("mouseenter", (event: MouseEvent, d: Datum) => {
                        // Show all points with the same x value
                        let points: {
                            yValue: number;
                            dataIndex: number;
                            pointIndex: number;
                            yVariableIndex: number;
                        }[] = [];
                        for (
                            let yVariableIndex = 0;
                            yVariableIndex < yVariables.length;
                            ++yVariableIndex
                        ) {
                            const yVariable = yVariables[yVariableIndex];
                            for (
                                let currentDataIndex = 0;
                                currentDataIndex < modifiedData.length;
                                ++currentDataIndex
                            ) {
                                if (currentDataIndex !== dataIndex) {
                                    let point = modifiedData[
                                        currentDataIndex
                                    ].find(
                                        (point) =>
                                            point[xVariableName] ===
                                            (d[xVariableName] as number)
                                    );
                                    if (point != null) {
                                        points.push({
                                            yValue:
                                                config.stacked && config.isArea
                                                    ? (point[
                                                          yVariable.name
                                                      ] as number) +
                                                      (point[
                                                          `${yVariable.name}_offset`
                                                      ] as number)
                                                    : (point[
                                                          yVariable.name
                                                      ] as number),
                                            dataIndex: currentDataIndex,
                                            pointIndex: point.pointIndex as number,
                                            yVariableIndex: yVariableIndex,
                                        });
                                    }
                                } else {
                                    points.push({
                                        yValue:
                                            config.stacked && config.isArea
                                                ? (d[
                                                      yVariable.name
                                                  ] as number) +
                                                  (d[
                                                      `${yVariable.name}_offset`
                                                  ] as number)
                                                : (d[yVariable.name] as number),
                                        dataIndex: dataIndex,
                                        pointIndex: d.pointIndex as number,
                                        yVariableIndex: yVariableIndex,
                                    });
                                }
                            }
                        }
                        setShowTooltipInfo({
                            x: event.x,
                            y: event.y,
                            key: xVariableName,
                            keyValue: d[xVariableName] as number,
                            points: points,
                        });
                    })
                    .on("mousemove", (event: MouseEvent, _d: Datum) => {
                        setShowTooltipInfo((showTooltipInfo) => {
                            if (showTooltipInfo == null) return null;
                            return {
                                ...showTooltipInfo,
                                x: event.x + 5,
                                y: event.y + 5,
                            };
                        });
                    })
                    .on("mouseleave", () => {
                        setShowTooltipInfo(null);
                    })
                    .on("click", (_evt: MouseEvent, d: Datum) => {
                        if (colorPickerIsEnabled) {
                            onDotClick(index, d.pointIndex as number);
                        }
                    });
            }
        }

        chartOptionsRef.current = {
            svg: svg,
            height: height,
        };
    }, [
        data,
        maxXRange,
        maxYRange,
        minXRange,
        minYRange,
        config.nameColorMapping,
        config.groupNameColorMapping,
        config.formatAxises,
        axesLinesColor,
        ticksColor,
        ticksAndLabels,
        //   onDotClick,
        isTimeSeries,
        props.preview,
        props.index,
        config.dotRadius,
        config.dotColors,
        parentRef.current?.clientHeight,
        parentRef.current?.clientWidth,
        config.isArea,
        config.gradientArea,
        config.stacked,
        // The chart should be re-rendered if one of the below values changes
        config.showLegend,
        config.showXAxisName,
        config.showYAxisName,
        config.lineType,
        colorPickerIsEnabled,
        longestYAxisValue,
        dataIndices,
        dataXMax,
        dataXMin,
        dataYMax,
        dataYMin,
        modifiedData,
        props.config?.formatAxises?.xAxisDate?.value,
        props.config?.formatAxises?.yAxis,
        props.config?.ticksAndLabels?.x?.interval,
        props.config?.ticksAndLabels?.x?.labelOrientation,
        props.config?.ticksAndLabels?.y?.interval,
        props.config?.ticksAndLabels?.y?.labelOrientation,
        props.content.time,
        props.dashboardId,
        xVariableName,
        xAxisFontSize,
        yAxisFontSize,
        yVariables,
        nextChartDatasetIndex,
        onDotClick,
        props.config?.formatAxises?.xAxis,
        xVariable.name,
    ]);
    useEffect(() => {
        if (verticalLineRef.current != null) {
            verticalLineRef.current.remove();
        }
        if (showTooltipInfo && chartOptionsRef.current) {
            let { svg, height } = chartOptionsRef.current;
            let g = svg.append("g");

            // Vertical line
            g.append("line")
                .attr("y1", 0)
                .attr("y2", height)
                .attr("x1", xScale.current!(showTooltipInfo.keyValue!)!)
                .attr("x2", xScale.current!(showTooltipInfo.keyValue!)!)
                .attr("fill", "none")
                .attr(
                    "stroke",
                    axesLinesColor ??
                        mainStyle.getPropertyValue("--graphs-stroke-color")
                )
                .attr("stroke-dasharray", 2)
                .attr("stroke-width", 2)
                .attr("pointer-events", "none");

            // Circle
            let x = showTooltipInfo!.keyValue;
            g.selectAll("circle")
                .data(showTooltipInfo!.points)
                .join("circle")
                .attr("cx", xScale.current!(x)!)
                .attr("cy", (d) => yScale.current!(d.yValue))
                .attr("r", config.dotRadius > 0 ? config.dotRadius : 4)
                .attr("fill", "none")
                .attr("stroke", (d) =>
                    color(
                        yVariables,
                        props.index < 0,
                        props.preview ?? false,
                        config.nameColorMapping,
                        config.groupNameColorMapping,
                        yVariables[d.yVariableIndex],
                        d.dataIndex,
                        d.yVariableIndex
                    )
                )
                .attr("stroke-width", 2)
                .attr("hidden", (d) =>
                    x < minXRange ||
                    x > maxXRange ||
                    d.yValue < minYRange ||
                    d.yValue > maxYRange
                        ? true
                        : null
                )
                .attr("pointer-events", "none");
            verticalLineRef.current = g;
        }
    }, [
        showTooltipInfo?.keyValue,
        axesLinesColor,
        // The chart should be re-rendered if one of the below values changes
        config.showLegend,
        config.showXAxisName,
        config.showYAxisName,
        showTooltipInfo,
        config.dotRadius,
        config.groupNameColorMapping,
        config.nameColorMapping,
        data,
        props.groupNames,
        props.index,
        props.preview,
        modifiedData,
        yVariables,
        maxXRange,
        maxYRange,
        minXRange,
        minYRange,
    ]);

    let tooltipStyle = TooltipStyles(tooltipColor, tooltipFontSize);
    return (
        <div
            ref={parentRef}
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                position: "relative",
                flexDirection: "column",
                // Resizing does not work properly
                // without overflow: "hidden"
                overflow: "hidden",
            }}
        >
            {props.content.time?.variableIndex != null &&
                props.content.time?.uniqueValues.length !== 0 && (
                    <AnimationSlider
                        sliderStyle={{
                            cursor: "default",
                            width: 165,
                            pointerEvents: "auto",
                        }}
                        sliderIntervalTime={1000}
                        withTranstion={true}
                        values={props.content.time.uniqueValues}
                        onChange={(index) => {
                            setNextChartDatasetIndex(index);
                            updateChart(index);
                        }}
                        onChangeConfig={props.onChangeConfig}
                        config={props.config}
                    />
                )}
            <svg ref={svgRef} />
            {showTooltipInfo &&
                (() => {
                    const yFormat = props.config?.formatAxises?.yAxis;
                    let xLabel = "";
                    if (isTimeSeries) {
                        xLabel = formatXDateLabel(
                            props.config?.formatAxises?.xAxisDate?.value ??
                                dateTimeFormatOptionsForLevels[
                                    props.config?.ticksAndLabels?.x
                                        ?.intervalUnit ?? "day"
                                ][0].value,
                            showTooltipInfo.keyValue as number
                        );
                    } else {
                        xLabel = formatLabel(
                            props.config?.formatAxises?.xAxis,
                            showTooltipInfo.keyValue
                        );
                    }

                    return (
                        <Portal rootNode={document.body}>
                            {/* Do not remove this code. It's for a future task.
                                <div
                                    style={{
                                        zIndex: 100000,
                                        position: "absolute",
                                        top: showTooltipInfo.y,
                                        left: showTooltipInfo.x + 5,
                                        ...tooltipStyle.contentStyle,
                                        pointerEvents: "none",
                                        userSelect: "none",
                                    }}
                                >
                                    <TooltipContent
                                        tooltipConfig={
                                            props.config.tooltipConfig ?? {}
                                        }
                                        onChange={(newTooltipConfig) => {
                                            props.onChangeConfig({
                                                ...props.config,
                                                tooltipConfig: newTooltipConfig,
                                            });
                                        }}
                                        scale={1}
                                        editable={false}
                                        data={
                                            tooltipData[showTooltipInfo.pointIndex]
                                        }
                                        tooltipId={props.dashboardId}
                                    />
                                </div>
                            */}
                            <div
                                style={{
                                    zIndex: 100000,
                                    position: "absolute",
                                    top: showTooltipInfo.y,
                                    left: showTooltipInfo.x + 5,
                                    padding: "10px",
                                    ...tooltipStyle.contentStyle,
                                    display: "flex",
                                    flexDirection: "column",
                                    pointerEvents: "none",
                                }}
                            >
                                <span
                                    className="unselectable"
                                    style={tooltipStyle.labelStyle}
                                >
                                    {`${showTooltipInfo.key}: ${xLabel}`}
                                </span>
                                {showTooltipInfo.points.map((point) => (
                                    <span
                                        style={{
                                            ...tooltipStyle.itemStyle,
                                            color: color(
                                                yVariables,
                                                props.index < 0,
                                                props.preview ?? false,
                                                config.nameColorMapping,
                                                config.groupNameColorMapping,
                                                yVariables[
                                                    point.yVariableIndex
                                                ],
                                                point.dataIndex,
                                                point.yVariableIndex
                                            ),
                                        }}
                                        className="unselectable"
                                    >{`${
                                        yVariables[point.yVariableIndex].name
                                    }: ${formatLabel(
                                        yFormat,
                                        point.yValue
                                    )}`}</span>
                                ))}
                            </div>
                        </Portal>
                    );
                })()}
        </div>
    );
}
