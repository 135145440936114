import React, { Component } from "react";
import Select, { createFilter } from "react-select";
import CreatableSelect from "react-select/creatable";
import { Button } from "react-bootstrap";
import Switch from "react-switch";

import axios from "common/ServerConnection";
import Instrumentation from "common/Instrumentation";
import UpdateStatusAlert from "../../common/UpdateStatusAlert";
import UpdateStatus from "../../common/UpdateStatus";
import FieldEditor, { Field } from "../../common/FieldEditor";
import customSelectStyles from "common/SelectStyles";
import UserGroupsStore, { UserGroupOption } from "common/UserGroupsStore";
import { UserButton } from "common/UserIcon";
import PasswordChangePopup from "common/PasswordChangePopup";
import { Group } from "common/UserGroupsApi";
import stringSimilarity from "string-similarity";
import ReactPaginate from "react-paginate";

interface ExtendedUserInfo {
    id: number;
    user_name: string;
    first_name: string;
    last_name: string;
    icon_url: string;
    position: string;
    organization: string;
    phone_number: string;
    apache_group: string | null;
    email: string;
    user_groups?: (Group | null)[];
    internal_user: boolean;
    fee_percent: number;
}

const similarity = (userInfo: ExtendedUserInfo, searchText: string) => {
    // search criteria
    //title
    let metrics = 0;
    let weight = 1;
    if (userInfo.first_name) {
        metrics +=
            stringSimilarity.compareTwoStrings(
                userInfo.first_name.toLowerCase(),
                searchText.toLowerCase()
            ) * 0.2;
        weight = weight - 0.2;
    }
    if (userInfo.last_name) {
        metrics +=
            stringSimilarity.compareTwoStrings(
                userInfo.last_name.toLowerCase(),
                searchText.toLowerCase()
            ) * 0.2;
        weight = weight - 0.2;
    }
    if (userInfo.email) {
        metrics +=
            stringSimilarity.compareTwoStrings(
                userInfo.email.toLowerCase(),
                searchText.toLowerCase()
            ) * 0.3;
        weight = weight - 0.3;
    }
    if (userInfo.user_name) {
        metrics +=
            stringSimilarity.compareTwoStrings(
                userInfo.user_name.toLowerCase(),
                searchText.toLowerCase()
            ) * weight;
    }
    return metrics;
};

const userQueryLimit = 5;
const maxUsersPageCount = 5;

let prevSearchText = "";

const searchUsers = (
    users: (ExtendedUserInfo & { index: number })[],
    searchText: string
): (ExtendedUserInfo & { index: number })[] => {
    const filteredUsers = users
        .map((user) => ({
            user: user,
            similarity: similarity(user, searchText),
        }))
        .filter((user) => user.similarity > 0.01)

    if (prevSearchText !== searchText) {
        filteredUsers.sort((u1, u2) => {
            if (u1.similarity < u2.similarity) return 1;
            if (u1.similarity > u2.similarity) return -1;
            return 0;
        })
    }

    prevSearchText = searchText;
    return filteredUsers.map((user) => user.user);
};

interface ExtendedUpdateUserInfo {
    id: number;
    user_name: string;
    first_name: string;
    last_name: string;
    icon_url: string;
    position: string;
    organization: string;
    phone_number: string;
    apache_group: string | null;
    email: string;
    user_groups?: (string | null)[];
    internal_user: boolean;
    fee_percent: number;
}

interface State {
    status: UpdateStatus;
    errorMessage: string;
    userListStatus: UpdateStatus;
    userListErrorMessage: string;
    value: Field[];
    userGroups: (Group | null)[];
    users: (ExtendedUserInfo & { index: number })[];
    createdUserGroups: string[];
    showPasswordResetPopup: boolean;
    userNameForResetPassword: string;
    userSearchInput: string;
    internalUser: boolean;
    pageCount: number;
    itemOffset: number;
}

class MainComponent extends Component<{}, State> {
    private performance: Date | null;

    constructor(props: {}) {
        super(props);
        this.state = {
            userSearchInput: "",
            status: UpdateStatus.NotUploaded,
            errorMessage: "",
            userListStatus: UpdateStatus.NotUploaded,
            userListErrorMessage: "",
            value: [
                {
                    name: "user_name",
                    label: "Username",
                    value: "",
                    editable: true,
                    hidden: false,
                },
                {
                    name: "password",
                    label: "Password",
                    value: "",
                    editable: true,
                    hidden: true,
                    passwordStrength: true,
                },
                {
                    name: "confirm_password",
                    label: "Confirm",
                    value: "",
                    editable: true,
                    hidden: true,
                },
                {
                    name: "first_name",
                    label: "First Name",
                    value: "",
                    editable: true,
                    hidden: false,
                },
                {
                    name: "last_name",
                    label: "Last Name",
                    value: "",
                    editable: true,
                    hidden: false,
                },
                {
                    name: "organization",
                    label: "Organization",
                    value: "",
                    editable: true,
                    hidden: false,
                },
                {
                    name: "position",
                    label: "Position",
                    value: "",
                    editable: true,
                    hidden: false,
                },
                {
                    name: "phone_number",
                    label: "Phone Number",
                    value: "",
                    editable: true,
                    hidden: false,
                },
                {
                    name: "email",
                    label: "Email",
                    value: "",
                    editable: true,
                    hidden: false,
                },
                {
                    name: "icon_url",
                    label: "Icon",
                    value: "",
                    icon: true,
                    hidden: false,
                },
                {
                    name: "apache_group",
                    label: "Apache group",
                    value: "",
                    select: true,
                    options: [],
                },
            ],
            userGroups: [null],
            users: [],
            pageCount: 0,
            itemOffset: 0,
            showPasswordResetPopup: false,
            userNameForResetPassword: "",
            createdUserGroups: [],
            internalUser: false,
        };
        this.handlePageClick = this.handlePageClick.bind(this);
        this.performance = null;
    }

    componentDidMount() {
        UserGroupsStore.init();
        axios
            .post<{
                success: boolean;
                error_msg: string;
                apache_groups?: string[];
            }>("/api/get_apache_groups", {})
            .then((response) => {
                if (
                    response.data.success &&
                    response.data.apache_groups != null
                ) {
                    this.setState((state) => {
                        let value = Array.from(state.value);
                        value[value.length - 1] = {
                            ...value[value.length - 1],
                            options: response.data.apache_groups,
                        };
                        return {
                            value: value,
                        };
                    });
                } else {
                    console.log(response.data.error_msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        this.getUsers();
    }

    componentDidUpdate() {
        if (this.performance != null) {
            let timeMs: number =
                new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction("Settings", timeMs);
        }
    }

    private createNewUser(): void {
        this.setState({
            status: UpdateStatus.Loading,
            errorMessage: "",
        });
        let config: { [key: string]: string | string[] | boolean } = {};
        this.state.value.forEach((field: Field) => {
            config[field.name] = field.value;
        });
        config["user_groups"] = this.state.userGroups
            .filter((value: Group | null): value is Group => value != null)
            .map((value): string => value.name);
        if (config["password"] !== config["confirm_password"]) {
            this.setState({
                status: UpdateStatus.Error,
                errorMessage: "Passwords didn't match",
            });
            return;
        }
        config["internal_user"] = this.state.internalUser;
        axios
            .post<{
                success: boolean;
                error_msg: string;
            }>("/api/create_new_user", config)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        status: UpdateStatus.Success,
                        createdUserGroups: [],
                    });
                    this.getUsers();
                    UserGroupsStore.updateUserGroups();
                } else {
                    console.log(response.data.error_msg);
                    this.setState({
                        status: UpdateStatus.Error,
                        errorMessage: response.data.error_msg,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    status: UpdateStatus.Error,
                });
            });
    }

    private getUsers(): void {
        axios
            .post<{
                success: boolean;
                error_msg: string;
                users?: ExtendedUserInfo[];
            }>("/api/get_users", {})
            .then((response) => {
                if (response.data.success && response.data.users != null) {
                    this.setState({
                        users: response.data.users.map((user, index) => ({
                            ...user,
                            index: Number(index),
                        })),
                        pageCount: Math.ceil(
                            response.data.users.length / maxUsersPageCount
                        ),
                    });
                } else {
                    console.log(response.data.error_msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    private buildUserGroupsInner(
        userGroups: (Group | null)[],
        updateStateField: (userGroups: (Group | null)[]) => void
    ): JSX.Element {
        return (
            <div className="flex-simple-column" style={{ marginTop: "5px" }}>
                {userGroups.map((userGroup, index) => (
                    <div
                        className="my-row"
                        key={index}
                        style={{ marginTop: "5px" }}
                    >
                        <CreatableSelect
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            isClearable={false}
                            placeholder={"Enter group"}
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            styles={{
                                ...customSelectStyles,
                                container: (base) => ({
                                    ...base,
                                    height: "38px",
                                    width: "140px",
                                }),
                            }}
                            options={UserGroupsStore.userGroupsOptions.concat(
                                this.state.createdUserGroups.map(
                                    (item, index) => ({
                                        label: item,
                                        value: item,
                                        id: -index - 1,
                                    })
                                )
                            )}
                            value={
                                userGroup
                                    ? {
                                          label: userGroup.name,
                                          value: userGroup.name,
                                          id: userGroup.id,
                                      }
                                    : null
                            }
                            onChange={(newValue) => {
                                let newUserGroups = Array.from(userGroups);
                                newUserGroups[index] = {
                                    name: (newValue as UserGroupOption).value,
                                    id: (newValue as UserGroupOption).id,
                                };
                                updateStateField(newUserGroups);
                            }}
                            onCreateOption={(option) => {
                                let createdUserGroups = Array.from(
                                    this.state.createdUserGroups
                                );
                                createdUserGroups.push(option);
                                this.setState({
                                    createdUserGroups: createdUserGroups,
                                });
                                let newUserGroups = Array.from(userGroups);
                                newUserGroups[index] = {
                                    id: -createdUserGroups.length,
                                    name: option,
                                };
                                updateStateField(newUserGroups);
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                        <div
                            className="flex-simple-column"
                            style={{ marginLeft: 5 }}
                        >
                            <Button
                                className="btn-small-like-select"
                                style={{
                                    width: "19px",
                                    height: "19px",
                                }}
                                onClick={() => {
                                    let newUserGroups = Array.from(userGroups);
                                    newUserGroups.splice(index + 1, 0, null);
                                    updateStateField(newUserGroups);
                                }}
                            >
                                {"\uFF0B" /* plus */}
                            </Button>
                            <Button
                                className="btn-small-like-select"
                                style={{
                                    width: "19px",
                                    height: "19px",
                                }}
                                onClick={() => {
                                    let newUserGroups = Array.from(userGroups);
                                    newUserGroups.splice(index, 1);
                                    if (newUserGroups.length === 0)
                                        newUserGroups.push(null);
                                    updateStateField(newUserGroups);
                                }}
                            >
                                {"\uFF0D" /* minus */}
                            </Button>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    private handlePageClick(event: { selected: number }): void {
        const newOffset =
            (event.selected * maxUsersPageCount) % this.state.users.length;
        this.setState({ itemOffset: newOffset });
    }

    private buildAdditionalSelectors(): JSX.Element {
        return (
            <>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                        justifyContent: "space-between",
                    }}
                >
                    <div
                        className="regular-text"
                        style={{
                            marginLeft: "19px",
                            paddingLeft: "15px",
                            marginRight: "15px",
                            minWidth: "8em",
                        }}
                    >
                        {"User groups"}
                    </div>
                    {this.buildUserGroupsInner(
                        this.state.userGroups,
                        (userGroups) => {
                            this.setState({
                                userGroups: userGroups,
                            });
                        }
                    )}
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                        justifyContent: "space-between",
                    }}
                >
                    <div
                        className="regular-text"
                        style={{
                            marginLeft: "19px",
                            paddingLeft: "15px",
                            marginRight: "15px",
                            minWidth: "8em",
                        }}
                    >
                        {"Internal user"}
                    </div>
                    <Switch
                        onChange={(checked) => {
                            this.setState({
                                internalUser: checked,
                            });
                        }}
                        checked={this.state.internalUser}
                        width={26}
                        height={13}
                        offColor="#20293C"
                        onColor="#20293C"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        offHandleColor="#70889E"
                        onHandleColor="#1F8EFA"
                    />
                </div>
            </>
        );
    }

    private buildUserListSection(): JSX.Element {
        let filterUsers = this.state.userSearchInput
            ? searchUsers(this.state.users, this.state.userSearchInput).slice(
                  0,
                  userQueryLimit
              )
            : this.state.users.slice(
                  this.state.itemOffset,
                  this.state.itemOffset + maxUsersPageCount
              );
        return (
            <div style={{ marginTop: 20, overflow: "auto" }}>
                <span className="big-title-span">{"Users"}</span>
                <div
                    style={{ marginTop: 10, minWidth: 300 }}
                    className="flex-simple-column"
                >
                    <input
                        value={this.state.userSearchInput}
                        className="like-select module-template"
                        type="text"
                        placeholder="Search user"
                        onChange={(evt) => {
                            let value = evt.target.value;
                            this.setState({
                                userSearchInput: value,
                            });
                        }}
                    />
                </div>
                <div
                    style={{ marginTop: 10, minWidth: 1650 }}
                    className="flex-simple-column"
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                        }}
                    >
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "140px",
                            }}
                        >
                            {"Username"}
                        </div>
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "140px",
                            }}
                        >
                            {"Apache groups"}
                        </div>
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "165px",
                            }}
                        >
                            {"User groups"}
                        </div>
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "140px",
                            }}
                        >
                            {"First name"}
                        </div>
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "140px",
                            }}
                        >
                            {"Last name"}
                        </div>
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "140px",
                            }}
                        >
                            {"Organization"}
                        </div>
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "140px",
                            }}
                        >
                            {"Position"}
                        </div>

                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "140px",
                            }}
                        >
                            {"Phone number"}
                        </div>
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "140px",
                            }}
                        >
                            {"Email"}
                        </div>
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "140px",
                            }}
                        >
                            {"Fee Percent"}
                        </div>
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "140px",
                            }}
                        >
                            {"Icon"}
                        </div>
                    </div>
                    {filterUsers.map((user) => {
                        let userGroups: (Group | null)[] = Array.from(
                            user.user_groups ?? []
                        );
                        let index = user.index;
                        if (userGroups.length === 0) userGroups.push(null);
                        return (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginTop: "10px",
                                        marginLeft: "20px",
                                    }}
                                >
                                    <div
                                        className="regular-text"
                                        style={{
                                            minWidth: "140px",
                                        }}
                                    >
                                        {user.user_name}
                                    </div>
                                    <div
                                        key={index}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginTop: "2px",
                                        }}
                                    >
                                        <Switch
                                            onChange={(checked) => {
                                                this.setState((state) => {
                                                    let users = Array.from(
                                                        state.users
                                                    );
                                                    users[index].internal_user =
                                                        checked;
                                                    return {
                                                        users: users,
                                                    };
                                                });
                                            }}
                                            checked={user.internal_user}
                                            width={26}
                                            height={13}
                                            offColor="#20293C"
                                            onColor="#20293C"
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                            offHandleColor="#70889E"
                                            onHandleColor="#1F8EFA"
                                        />
                                        <div
                                            className="regular-text"
                                            style={{
                                                marginLeft: "5px",
                                            }}
                                        >
                                            {"Internal"}
                                        </div>
                                    </div>
                                </div>
                                <Select
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={""}
                                    styles={{
                                        ...customSelectStyles,
                                        container: (base) => ({
                                            ...base,
                                            marginTop: "10px",
                                            marginLeft: "20px",
                                            height: "38px",
                                            minWidth: "140px",
                                        }),
                                    }}
                                    options={this.state.value[
                                        this.state.value.length - 1
                                    ].options?.map((option) => ({
                                        label: option,
                                        value: option,
                                    }))}
                                    value={{
                                        label: user.apache_group,
                                        value: user.apache_group,
                                    }}
                                    onChange={(newValue) => {
                                        let users = Array.from(
                                            this.state.users
                                        );
                                        users[index].apache_group = (
                                            newValue as {
                                                label: string;
                                                value: string;
                                            }
                                        ).value;
                                        this.setState({ users: users });
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                                <div style={{ marginLeft: "20px" }}>
                                    {this.buildUserGroupsInner(
                                        userGroups,
                                        (userGroups) => {
                                            this.setState((state) => {
                                                let users = Array.from(
                                                    state.users
                                                );
                                                users[index] = {
                                                    ...users[index],
                                                    user_groups: userGroups,
                                                };
                                                return { users: users };
                                            });
                                        }
                                    )}
                                </div>
                                <input
                                    type={"text"}
                                    className="like-select"
                                    style={{
                                        marginLeft: "20px",
                                        marginTop: "10px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        width: "140px",
                                    }}
                                    placeholder=""
                                    onChange={(e) => {
                                        let value: string = e.target.value;
                                        this.setState((state) => {
                                            let users = Array.from(state.users);
                                            users[index] = {
                                                ...users[index],
                                                first_name: value,
                                            };
                                            return { users: users };
                                        });
                                    }}
                                    value={user.first_name}
                                />
                                <input
                                    type={"text"}
                                    className="like-select"
                                    style={{
                                        marginLeft: "20px",
                                        marginTop: "10px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        width: "140px",
                                    }}
                                    placeholder=""
                                    onChange={(e) => {
                                        let value: string = e.target.value;
                                        this.setState((state) => {
                                            let users = Array.from(state.users);
                                            users[index] = {
                                                ...users[index],
                                                last_name: value,
                                            };
                                            return { users: users };
                                        });
                                    }}
                                    value={user.last_name}
                                />
                                <input
                                    type={"text"}
                                    className="like-select"
                                    style={{
                                        marginLeft: "20px",
                                        marginTop: "10px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        width: "140px",
                                    }}
                                    placeholder=""
                                    onChange={(e) => {
                                        let value: string = e.target.value;
                                        this.setState((state) => {
                                            let users = Array.from(state.users);
                                            users[index] = {
                                                ...users[index],
                                                organization: value,
                                            };
                                            return { users: users };
                                        });
                                    }}
                                    value={user.organization ?? ""}
                                />
                                <input
                                    type={"text"}
                                    className="like-select"
                                    style={{
                                        marginLeft: "20px",
                                        marginTop: "10px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        width: "140px",
                                    }}
                                    placeholder=""
                                    onChange={(e) => {
                                        let value: string = e.target.value;
                                        this.setState((state) => {
                                            let users = Array.from(state.users);
                                            users[index] = {
                                                ...users[index],
                                                position: value,
                                            };
                                            return { users: users };
                                        });
                                    }}
                                    value={user.position ?? ""}
                                />

                                <input
                                    type={"text"}
                                    className="like-select"
                                    style={{
                                        marginLeft: "20px",
                                        marginTop: "10px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        width: "140px",
                                    }}
                                    placeholder=""
                                    onChange={(e) => {
                                        let value: string = e.target.value;
                                        this.setState((state) => {
                                            let users = Array.from(state.users);
                                            users[index] = {
                                                ...users[index],
                                                phone_number: value,
                                            };
                                            return { users: users };
                                        });
                                    }}
                                    value={user.phone_number ?? ""}
                                />
                                <input
                                    type={"text"}
                                    className="like-select"
                                    style={{
                                        marginLeft: "20px",
                                        marginTop: "10px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        width: "140px",
                                    }}
                                    placeholder=""
                                    onChange={(e) => {
                                        let value: string = e.target.value;
                                        this.setState((state) => {
                                            let users = Array.from(state.users);
                                            users[index] = {
                                                ...users[index],
                                                email: value,
                                            };
                                            return { users: users };
                                        });
                                    }}
                                    value={user.email ?? ""}
                                />
                                <input
                                    type={"number"}
                                    className="like-select"
                                    style={{
                                        marginLeft: "20px",
                                        marginTop: "10px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        width: "140px",
                                    }}
                                    placeholder=""
                                    onChange={(e) => {
                                        let value: string = e.target.value;
                                        this.setState((state) => {
                                            let users = Array.from(state.users);
                                            users[index] = {
                                                ...users[index],
                                                fee_percent: +value,
                                            };
                                            return { users: users };
                                        });
                                    }}
                                    value={user.fee_percent ?? 15}
                                />
                                <div
                                    style={{
                                        marginLeft: "20px",
                                        width: "90px",
                                    }}
                                >
                                    <UserButton
                                        user={user}
                                        identifier={user.id.toString()}
                                        iconUrl={user.icon_url}
                                        onLoad={(result) => {
                                            if (typeof result === "string") {
                                                this.setState((state) => {
                                                    let users = Array.from(
                                                        state.users
                                                    );
                                                    users[index] = {
                                                        ...users[index],
                                                        icon_url: result,
                                                    };
                                                    return { users: users };
                                                });
                                            }
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        marginTop: "10px",
                                        marginLeft: "20px",
                                    }}
                                >
                                    <Button
                                        type="button"
                                        className="btn btn-sm btn-primary my-primary"
                                        onClick={() => {
                                            this.performance = new Date();
                                            this.updateUser(user);
                                        }}
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        type="button"
                                        className="btn btn-sm btn-primary my-primary"
                                        style={{ marginLeft: "5px" }}
                                        onClick={() => {
                                            this.performance = new Date();
                                            this.deleteUser(user);
                                        }}
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        type="button"
                                        className="btn btn-sm btn-primary my-primary"
                                        style={{
                                            marginLeft: "5px",
                                            marginTop: "5px",
                                        }}
                                        onClick={() => {
                                            this.performance = new Date();
                                            this.setState({
                                                userNameForResetPassword:
                                                    user.user_name,
                                                showPasswordResetPopup: true,
                                            });
                                        }}
                                    >
                                        Reset Password
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
                    {this.state.userListStatus !== UpdateStatus.NotUploaded && (
                        <UpdateStatusAlert
                            value={this.state.userListStatus}
                            onChange={(status: UpdateStatus) =>
                                this.setState({ userListStatus: status })
                            }
                            errorMessage={this.state.userListErrorMessage}
                        />
                    )}
                </div>
                {this.state.showPasswordResetPopup ? (
                    <PasswordChangePopup
                        section="Settings"
                        userName={this.state.userNameForResetPassword}
                        reset
                        onClose={() => {
                            this.setState({
                                showPasswordResetPopup: false,
                                userNameForResetPassword: "",
                            });
                        }}
                    />
                ) : null}
            </div>
        );
    }

    private deleteUser(user: ExtendedUserInfo): void {
        this.setState({
            userListStatus: UpdateStatus.Loading,
            userListErrorMessage: "",
        });

        let json = {
            id: user.id,
            user_name: user.user_name,
        };
        axios
            .post<{
                success: boolean;
                error_msg: string;
            }>("/api/delete_user", json)
            .then((response) => {
                if (response.data.success) {
                    this.setState((state) => {
                        let users = state.users.filter(
                            (item) => item.id !== user.id
                        );
                        return {
                            users: users,
                            userListStatus: UpdateStatus.Success,
                        };
                    });
                } else {
                    console.log(response.data.error_msg);
                    this.setState({
                        userListStatus: UpdateStatus.Error,
                        userListErrorMessage: response.data.error_msg,
                    });
                }
            })
            .catch((_error) => {
                this.setState({
                    userListStatus: UpdateStatus.Error,
                });
            });
    }

    private updateUser(user: ExtendedUserInfo): void {
        this.setState({
            userListStatus: UpdateStatus.Loading,
            userListErrorMessage: "",
        });

        let json: ExtendedUpdateUserInfo & { update?: boolean } = Object.assign(
            {},
            { ...user, user_groups: [], index: undefined }
        );

        json.user_groups = user.user_groups
            ?.filter((group: Group | null): group is Group => group != null)
            .map((group) => group.name);
        json.update = true;

        axios
            .post<{
                success: boolean;
                error_msg: string;
            }>("/api/create_new_user", json)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        userListStatus: UpdateStatus.Success,
                        createdUserGroups: [],
                    });
                    this.getUsers();
                    UserGroupsStore.updateUserGroups();
                } else {
                    console.log(response.data.error_msg);
                    this.setState({
                        userListStatus: UpdateStatus.Error,
                        userListErrorMessage: response.data.error_msg,
                    });
                }
            })
            .catch((_error) => {
                this.setState({
                    userListStatus: UpdateStatus.Error,
                });
            });
    }

    render() {
        return (
            <>
                <FieldEditor
                    title="Create New User"
                    value={this.state.value}
                    onChange={(value) => this.setState({ value: value })}
                    onUpdate={() => this.createNewUser()}
                    afterInputs={this.buildAdditionalSelectors()}
                    status={this.state.status}
                    onStatusChange={(status: UpdateStatus) =>
                        this.setState({ status: status })
                    }
                    errorMessage={this.state.errorMessage}
                />
                {this.buildUserListSection()}
                {!this.state.userSearchInput && (
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={this.handlePageClick}
                        pageRangeDisplayed={maxUsersPageCount}
                        pageCount={this.state.pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={undefined}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                    />
                )}
            </>
        );
    }
}

export { MainComponent };
export let requirePermission = "ManageUsers";
